import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import stethoscop from "../../images/material/stethoscop.png";

class Instrumentation extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">	
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Instrumentation</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Instrumentation</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={stethoscop} alt="Stéthoscope" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Stéthoscopes</h4>
											<p style={{ fontSize: '18px' }}>Offre une précision d'écoute des battements du cœur et des sons respiratoires.</p>
										</div>
									</div>
								</div>
							</div>	
						</div>
					</section>
				</div>
				
			</>
		);
	}
}

export default Instrumentation;