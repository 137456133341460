import React, { Component } from 'react';

class LatestNewsSection extends Component{
	render(){
		
		return(
			<>
				
				<section className="section-sp1 service-wraper2">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">+2</h2>
									<h5 className="ttr-title">Ans d'expérience</h5>
									<p style={{ fontSize: '18px' }}>Une expertise solide, forgée au cours de 2 années complètes, assurant un service de qualité exceptionnelle.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">+50</h2>
									<h5 className="ttr-title">Docteurs/infirmiers</h5>
									<p style={{ fontSize: '18px' }}>Partenariats solides avec plus de 50 professionnels de la santé, assurant des solutions médicales diversifiées.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">+100</h2>
									<h5 className="ttr-title">Patients</h5>
									<p style={{ fontSize: '18px' }}>Plus de 100 patients et clients satisfaits, une preuve de notre engagement envers votre bien-être.</p>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">+200</h2>
									<h5 className="ttr-title">Produits</h5>
									<p style={{ fontSize: '18px' }}>Une riche sélection de plus de 200 produits médicaux, soigneusement choisie pour répondre à vos divers besoins.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default LatestNewsSection;