import React, { Component } from 'react';

// Import Images
import pdf from "../../images/icon/pdf.png";

class WidgetDownload extends Component{
	render(){
		return(
			<>

                <div className="widget widget_download">
					<h4 className="widget-title">Téléchargement</h4>
					
					<a href="/catalogue-2023-CNO.pdf" className="download-link" download>
						<img src={pdf} alt="catalogue compléments alimentaires CNO"/>
						<h5 className="title">Notre catalogue CNO</h5>
						<span>Cliquer ici pour télécharger</span>
					</a>
					
				</div>
				
			</>
		);
	}
}

export default WidgetDownload;