import React, { Component } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// Layout
import Header from "./layout/header";
import Footer from "./layout/footer";

// All Pages Router
import Index from './pages/index';
import AboutUs from './pages/a-propos';
import Services from './pages/nos-produits';
import ServiceDetail from './pages/location-vente';
import ContactUs from './pages/contact';
import BlogGrid from './pages/blog';
import BlogDetails from './pages/blog-details';
import Error from './pages/error-404';
import Privacy from './pages/confidentialite';
import Legales from './pages/mentions-legales';
import Mobility from './pages/mobilite';
import Instrumentation from './pages/instrumentation';
import Incontinence from './pages/incontinence';
import Confort from './pages/confort-au-quotidien';
import Transfert from './pages/aide-aux-transferts';
import Health from './pages/hygiene-soins';
//import FormLogin from './pages/form-login';
//import FormRegister from './pages/form-register';
//import FormForgetPassword from './pages/form-forget-password';
//import Faq from './pages/faq';
//import Booking from './pages/booking';
//import Team from './pages/team';


class Markup extends Component{
	render(){
		return(
			<>	
			
				{/* {<BrowserRouter basename={'/react/'}> */}
				<BrowserRouter>
					
					<Routes>
						
						<Route element={<ThemeLayout />}>
							<Route path='/' element={<Index />} />
							<Route path='/a-propos' element={<AboutUs />} />
							<Route path='/nos-produits' element={<Services />} />
							<Route path='/location-vente' element={<ServiceDetail />} />
							<Route path='/contact' element={<ContactUs />} />
							<Route path='/blog' element={<BlogGrid />} />
							<Route path='/blog-details' element={<BlogDetails />} />
							<Route path='/confidentialite' element={<Privacy />} />
							<Route path='/mentions-legales' element={<Legales />} />
							<Route path='/mobilite' element={<Mobility />} />
							<Route path='/aide-aux-transferts' element={<Transfert />} />
							<Route path='/confort-au-quotidien' element={<Confort />} />
							<Route path='/incontinence' element={<Incontinence />} />
							<Route path='/instrumentation' element={<Instrumentation />} />
							<Route path='/hygiene-soins' element={<Health />} />
							{/*
							<Route path='/booking' element={<Booking />} />
							<Route path='/team' element={<Team />} />
							<Route path='/faq' element={<Faq />} />
							*/}
							<Route path="*" element={<Error />} />
						</Route>
						
						{/*
						<Route path="/form-login" element={<FormLogin />} />
						<Route path="/form-register" element={<FormRegister />} />
						<Route path='/form-forget-password' element={<FormForgetPassword />} />
						*/}
						
					</Routes>
					
					<PageScrollTop />
					
				</BrowserRouter>
				
				<BackToTop />
				
			</>
		);
	}
}
function ThemeLayout(){
	return(
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}
export default Markup;