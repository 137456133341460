import React, {Component} from 'react';
import {Accordion} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import bubble from '../../images/icon/bubble.png';

class Faqs extends Component{
	
	render(){
		return (
			<>
				<section className="section-sp1">
					<div className="container">
                    	<div className="heading-bx text-center">
							<h4 className="title-ext text-secondary">Foire Aux Questions</h4>
							<h2 className="title mb-0">
								Quelques tips
									<img src={bubble} alt="foire aux questions" style={{ marginLeft: '20px', width: '60px', height: '60px' }} />
							</h2>
						</div>
						<div className="row">
							<div className="col-lg-6">
								<Accordion className="accordion ttr-accordion1">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<span style={{ fontSize: '20px' }}>Je sors de chez mon médecin ou établissement hospitalier avec une ordonnance, que faire ?</span>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0" style={{ fontSize: '18px' }}>Dès que vous avez la prescription en main, n'hésitez pas à entrer en contact avec <strong style={{ fontWeight: 'bold' }}>Médi'Kay</strong>. Vous pouvez nous joindre par téléphone au <strong style={{ fontWeight: 'bold' }}>0696 94 96 21</strong>, par email à <strong style={{ fontWeight: 'bold' }}>contact@medikay.fr</strong>, ou en utilisant la rubrique <Link to="/contact">Contact</Link> sur notre site internet. Nous serons heureux de vous guider dans le processus de prise en charge de vos ordonnances.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>
											<span style={{ fontSize: '20px' }}>Quels sont les documents à préparer pour faciliter la prise en charge ?</span>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0" style={{ fontSize: '18px' }}>Nous vous prions de fournir une copie de votre <strong style={{ fontWeight: 'bold' }}>carte vitale</strong> ainsi que de <strong style={{ fontWeight: 'bold' }}>l'attestation de votre mutuelle</strong>. Ces documents sont nécessaires pour que nous puissions <strong style={{ fontWeight: 'bold' }}>prendre en charge</strong> vos besoins en matériel médical, en nutrition ou tout autre dispositif pour lequel une ordonnance est requise.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>
											<span style={{ fontSize: '20px' }}>En combien de temps serais-je livré ?</span>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0" style={{ fontSize: '18px' }}>Sauf pour les cas d'urgences exceptionnelles, notre engagement est de vous livrer dans un délai allant jusqu'à <strong style={{ fontWeight: 'bold' }}>48 heures</strong> à compter de votre commande.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header>
											<span style={{ fontSize: '20px' }}>Je vis ou suis hors du département Martinique, existe t'il des solutions ?</span>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0" style={{ fontSize: '18px' }}><strong style={{ fontWeight: 'bold' }}>Absolument</strong>. Nous sommes conscients des défis liés à la gestion de ce type de situation à distance. C'est pourquoi Médi'Kay déploie tous les efforts nécessaires pour vous offrir une <strong style={{ fontWeight: 'bold' }}>tranquillité d'esprit</strong>. Nous disposons désormais de diverses solutions à votre disposition. Vous pouvez suivre en direct l'état des livraisons depuis votre compte Médi'Kay, communiquer avec nous par <strong style={{ fontWeight: 'bold' }}>Email</strong>, <strong style={{ fontWeight: 'bold' }}>SMS</strong> ou <strong style={{ fontWeight: 'bold' }}>WhatsApp</strong>. Dans certaines circonstances, nous sommes même en mesure de nous rendre sur place pour vous fournir un rapport de situation.</p>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
							<div className="col-lg-6">
								<Accordion className="accordion ttr-accordion1">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											<span style={{ fontSize: '20px' }}>Comment me préparer pour la livraison ?</span>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0" style={{ fontSize: '18px' }}>Une fois que nous aurons convenu de la date de livraison, veuillez préparer vos <strong style={{ fontWeight: 'bold' }}>documents</strong> (attestation de carte vitale et mutuelle) ainsi que <strong style={{ fontWeight: 'bold' }}>l'espace d'accueil</strong> pour les dispositifs médicaux qui vous seront livrés. Cette préparation <strong style={{ fontWeight: 'bold' }}>facilitera</strong> leur installation.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>
											<span style={{ fontSize: '20px' }}>Le lit/fauteuil roulant, matelas à air... en location est-il à moi ?</span>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0" style={{ fontSize: '18px' }}><strong style={{ fontWeight: 'bold' }}>Non</strong>, tout le matériel en location demeure la <strong style={{ fontWeight: 'bold' }}>propriété de Médi'Kay</strong>. Par conséquent, il est recommandé d'en prendre soin afin que l'on puisse les récupérer dans les meilleures conditions.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>
											<span style={{ fontSize: '20px' }}>Comment me préparer à la récupération du matériel médical présent chez moi ?</span>
										</Accordion.Header>
										<Accordion.Body>
											<p className="mb-0" style={{ fontSize: '18px' }}>Après avoir convenu de la date de restitution du dispositif, il est impératif de le rendre en <strong style={{ fontWeight: 'bold' }}>bon état de propreté</strong>, sans aucune saleté ni résidu. Tout dispositif restitué dans un état sale pourrait donner lieu à une <strong style={{ fontWeight: 'bold' }}>facturation</strong>.</p>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
					</div>
				</section>					
			</>
		);
	}
}

export default Faqs;