import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import verticallift from "../../images/material/verticallift.png";
import standinglift from "../../images/material/standinglift.png";
import help from "../../images/material/help.png";
import sangles from "../../images/material/belts.png";

class Transfert extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">	
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Aide aux transferts</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Aides aux transferts</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<img src={standinglift} alt="Lève-personne" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Lève-personnes</h4>
											<p style={{ fontSize: '18px' }}>Facilite les transferts en toute sécurité.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={verticallift} alt="Verticalisateur" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Verticalisateurs</h4>
											<p style={{ fontSize: '18px' }}>Aide à la mobilité verticale avec stabilité.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={sangles} alt="Sangles" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Sangles</h4>
											<p style={{ fontSize: '18px' }}>Indispensables pour un levage sûr.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={help} alt="Aides techniques" style={{ width: '100px', height: '100px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Aides techniques</h4>
											<p style={{ fontSize: '18px' }}>Simplifient la vie quotidienne.</p>
										</div>
									</div>
								</div>
							</div>	
						</div>
					</section>
				</div>
				
			</>
		);
	}
}

export default Transfert;