import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import hygiene from "../../images/material/hygiene.png";
import pee from "../../images/material/pee.png";
import confort from "../../images/material/confort.png";
import stethoscope from "../../images/material/stethoscope.png";
import ambulance from "../../images/material/ambulance.png";
import belts from "../../images/material/belts.png";



class Services extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Nos produits</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Nos produits</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<img src={ambulance} alt="Mobilité" style={{ width: '60px', height: '60px' }} />
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Mobilité</h3>
											<p style={{ fontSize: '18px' }}>Des solutions qui améliorent la mobilité au quotidien, pour une vie active et indépendante.</p>
											<Link to="/mobilite" className="btn btn-primary light">En savoir plus</Link>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={confort} alt="Confort au quotidien" style={{ width: '70px', height: '70px' }} />
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Confort au quotidien</h3>
											<p style={{ fontSize: '18px' }}>Des produits conçus pour offrir un confort optimal dans les activités quotidiennes.</p>
											<Link to="/confort-au-quotidien" className="btn btn-primary light">En savoir plus</Link>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature3">
										<div className="feature-box-xl mb-20">
											<img src={pee} alt="Incontinence" style={{ width: '64px', height: '64px' }} />
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Incontinence</h3>
											<p style={{ fontSize: '18px' }}>Des solutions efficaces pour gérer l'incontinence de manière discrète et confortable.</p>
											<Link to="/incontinence" className="btn btn-primary light">En savoir plus</Link>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={belts} alt="Aide aux transferts" style={{ width: '60px', height: '60px' }} />
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Aide aux transferts</h3>
											<p style={{ fontSize: '18px' }}>Des produits et équipements conçus pour faciliter et sécuriser les transferts en toute simplicité.</p>
											<Link to="/aide-aux-transferts" className="btn btn-primary light">En savoir plus</Link>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature3">
										<div className="feature-box-xl mb-20">
											<img src={hygiene} alt="Hygiène et soins" style={{ width: '55px', height: '55px' }} />
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Hygiène/Soins</h3>
											<p style={{ fontSize: '18px' }}>Des produits essentiels pour maintenir une hygiène personnelle de qualité et des soins appropriés.</p>
											<Link to="/hygiene-soins" className="btn btn-primary light">En savoir plus</Link>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<img src={stethoscope} alt="Instrumentation médical" style={{ width: '70px', height: '70px' }} />
										</div>
										<div className="icon-content">
											<h3 className="ttr-title">Instrumentation</h3>
											<p style={{ fontSize: '18px' }}>Des équipements médicaux de pointe pour des diagnostics et des soins de qualité.</p>
											<Link to="/instrumentation" className="btn btn-primary light">En savoir plus</Link>
										</div>
									</div>
								</div>
							</div>	
						</div>
					</section>
				</div>
			</>
		);
	}
}

export default Services;