import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Import Images
import testShape from "../../images/testimonials/shape.png"
import pic1 from "../../images/testimonials/pic1.png"
import pic2 from "../../images/testimonials/pic2.png"
import pic3 from "../../images/testimonials/pic3.png"
import pic4 from "../../images/testimonials/pic4.png"
import pic5 from "../../images/testimonials/pic5.png"
import pic6 from "../../images/testimonials/pic6.png"
import plusOrange from "../../images/shap/plus-orange.png"
import squareBlue from "../../images/shap/square-blue.png"
import circleDots from "../../images/shap/circle-dots.png"
import circleOrange2 from "../../images/shap/circle-orange-2.png"

class testimonialSection extends Component{
	
	render(){
			
		const settings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
					
				
				<section className="section-area section-sp3 testimonial-wraper">
					<div className="container">
						<div className="heading-bx text-center">
							<h4 className="title-ext text-secondary">Nos avis</h4>
							<h2 className="title mb-0">Ce qu'ils disent de nous</h2>
						</div>
						<div className="row align-items-center">
							<div className="col-lg-6 text-center">
								<div className="thumb-wraper">
									<img className="bg-img" src={testShape} alt=""/>
									<ul>
										<li data-member="1"><Link to="#"><img src={pic1} alt=""/></Link></li>
										<li data-member="2"><Link to="#"><img src={pic2} alt=""/></Link></li>
										<li data-member="3"><Link to="#"><img src={pic3} alt=""/></Link></li>
										<li data-member="4"><Link to="#"><img src={pic4} alt=""/></Link></li>
										<li data-member="5"><Link to="#"><img src={pic5} alt=""/></Link></li>
										<li data-member="6"><Link to="#"><img src={pic6} alt=""/></Link></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-6">
								<Slider {...settings} className="testimonial-slide">
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>J'ai récemment fait appel à Medi'Kay pour louer un fauteuil roulant pendant la récupération de ma mère après une opération. Leur équipe a été incroyablement réactive et professionnelle. Ils ont pris le temps de nous conseiller sur le modèle le plus approprié et de nous expliquer son utilisation. Le fauteuil roulant était en parfait état, et la livraison a été rapide. Je recommande vivement Medi'Kay pour leur excellent service et leur personnel attentionné.</p>
											</div>
											<div className="client-info">
												<h5 className="name">Caroline Leroy</h5>
												<p>cliente</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>Medi'Kay est véritablement un sauveur en temps de besoin. Lorsque ma grand-mère a eu besoin d'un lit médicalisé, nous avons trouvé la solution parfaite chez eux. L'équipe a géré toutes les formalités administratives pour nous, ce qui a été d'une grande aide. Le lit était confortable et de haute qualité. Ils ont vraiment rendu cette période difficile beaucoup plus facile. Merci, Medi'Kay !</p>
											</div>
											<div className="client-info">
												<h5 className="name">Philippe Rousseau</h5>
												<p>client</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>J'ai été impressionnée par l'expertise de l'équipe de Medi'Kay. J'avais besoin d'un appareil respiratoire étant atteinte de BPCO, et ils ont su me guider vers le choix parfait. Leur connaissance approfondie des produits médicaux m'a rassuré, et ils ont même pris le temps de me montrer comment utiliser correctement l'appareil. C'est une entreprise qui connaît son métier.</p>
											</div>
											<div className="client-info">
												<h5 className="name">Sandrine Morel</h5>
												<p>patiente</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>Medi'Kay offre vraiment un choix impressionnant de produits médicaux. J'ai eu la chance de trouver tout ce dont j'avais besoin pour prendre soin de ma mère qui est en convalescence. Des déambulateurs aux prothèses, en passant par les lits médicalisés, ils ont tout. C'est un guichet unique pour les besoins médicaux, et je suis reconnaissant de les avoir trouvés.</p>
											</div>
											<div className="client-info">
												<h5 className="name">Nicolas Lefebvre</h5>
												<p>client</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>Je tiens à remercier l'équipe de Medi'Kay pour leur service personnalisé. Ils ont pris le temps de comprendre les besoins spécifiques de ma fille handicapée et nous ont recommandé un fauteuil roulant sur mesure qui a fait toute la différence dans sa vie quotidienne. Leur dévouement envers leurs clients est vraiment exceptionnel.</p>
											</div>
											<div className="client-info">
												<h5 className="name">Isabelle Moreau</h5>
												<p>cliente</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
									<div className="slider-item">
										<div className="testimonial-bx">
											<div className="testimonial-content">
												<p>Medi'Kay est véritablement une référence en matière de matériel médico-chirurgical. Leur réputation dans le secteur de la santé est amplement méritée. Leur expertise et leur professionnalisme se reflètent dans chaque interaction que j'ai eue avec eux. Si vous recherchez des solutions médicales de haute qualité, ne cherchez pas plus loin que Medi'Kay.</p>
											</div>
											<div className="client-info">
												<h5 className="name">Julien Gagnon</h5>
												<p>patient</p>
											</div>
											<div className="quote-icon">
												<i className="fas fa-quote-left"></i>
											</div>
										</div>
									</div>
								</Slider>
							</div>	 
						</div>	 
					</div>
					<img className="pt-img1 animate1" src={plusOrange} alt=""/>
					<img className="pt-img2 animate2" src={squareBlue} alt=""/>
					<img className="pt-img3 animate3" src={circleDots} alt=""/>
					<img className="pt-img4 animate4" src={circleOrange2} alt=""/>
				</section>
				
			</>
		);
	}
}

export default testimonialSection;