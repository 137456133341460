import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import logoMedikay from '../../images/logoMedikay.png';
import footerBg from '../../images/background/footer.jpg';
import ptImg1 from '../../images/shap/wave-blue.png';
import ptImg2 from '../../images/shap/circle-dots.png';
import ptImg3 from '../../images/shap/plus-blue.png';
import ptImg4 from '../../images/shap/wave-blue.png';

// Social Images
import instagram from '../../images/social/instagram.png';
import whatsapp from '../../images/social/whatsapp.png'
import facebook from '../../images/social/facebook.png'

class aboutSection extends Component{
	render(){
		const currentYear = new Date().getFullYear();

		return(
			<>
				
				<footer className="footer" style={{backgroundImage: "url("+footerBg+")"}}>
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-lg-3 col-md-6">
									<div className="widget widget_info">
										<div className="footer-logo">
											<Link to="/"><img src={logoMedikay} alt=""/></Link>
										</div>
										<div className="ft-contact">
											<p style={{ fontSize: '17px' }}>Medi'Kay, à vos côtés pour des solutions médicales évolutives. Votre bien-être est notre priorité.</p>
											<div className="contact-bx">
												<div className="icon"><i className="fas fa-phone-alt"></i></div>
												<div className="contact-number">
													<span style={{ fontSize: '17px' }}>Restons en contact !</span>
													<h4 className="number">06 96 94 96 21</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-6">
									<div className="widget footer_widget ml-50">
										<h3 className="footer-title">Nos services</h3>
										<ul>
											<li><Link to="/"><span>Accueil</span></Link></li>
										    {/*<li><Link to="/blog"><span>Blog</span></Link></li>*/}
											<li><Link to="/nos-produits"><span>Nos produits</span></Link></li>
											<li><Link to="/location-vente"><span>Location / Vente</span></Link></li>
										</ul>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-6">
									<div className="widget footer_widget">
										<h3 className="footer-title">Informations</h3>
										<ul>
											<li><Link to="/a-propos"><span>À propos</span></Link></li>
											<li><Link to="/contact"><span>Contact</span></Link></li>
											<li><Link to="/mentions-legales"><span>Mentions légales</span></Link></li>
											<li><Link to="/confidentialite"><span>Confidentialité</span></Link></li>

										</ul>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 col-md-6">
									<div className="widget widget_form">
										<h3 className="footer-title">Newsletter</h3>
										<form className="subscribe-form subscription-form mb-30">
											<div className="ajax-message"></div>
											<div className="input-group">
												<input name="email" required="required" className="form-control" placeholder="Adresse email" type="email"/>
											</div>
											<button name="submit" value="Submit" type="submit" className="btn btn-secondary shadow w-100">S'inscrire</button>	
										</form>
										<div className="footer-social-link">
											<ul>
												<li><a target="_blank" rel="noreferrer" href="https://wa.me/message/CHVJTHQVYD7JO1"><img src={whatsapp} alt="whatsapp"/></a></li>
												{/* <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/"><img src={instagram} alt="instagram"/></a></li>
												<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/"><img src={facebook} alt="facebook"/></a></li> */}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="footer-bottom">
							<div className="row">
								<div className="col-12 text-center">
									<p className="copyright-text" style={{ fontSize: '17px' }}>Copyright &copy; {currentYear} Design & Developed by <a href="https://virtuava.com" rel="noreferrer" target="_blank" className="text-secondary">Virtuava</a></p>
								</div>
							</div>
						</div>
					</div>
					<img className="pt-img1 animate-wave" src={ptImg1} alt=""/>
					<img className="pt-img2 animate1" src={ptImg2} alt=""/>
					<img className="pt-img3 animate-rotate" src={ptImg3} alt=""/>
					<img className="pt-img4 animate-wave" src={ptImg4} alt=""/>
				</footer>
			
			</>
		);
	}
}

export default aboutSection;
