import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logoMedikay from '../../images/logoMedikay.png';


const Header = () => {
	
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [activeItem, setActiveItem] = useState(null);
	const [isMobileView, setIsMobileView] = useState(false);
	
	const toggleSubmenu = (item) => {
		setActiveItem(item === activeItem ? null : item);
	};
	
	const toggleMenu = () => {
		setIsMenuOpen((prev) => !prev);
	};

	const handleMenuLinkClick = () => {
		if (window.innerWidth <= 991) {
			setIsMenuOpen(false);
		}
	};

	const handleMenuCloseClick = () => {
		setIsMenuOpen(false);
	};
	

	
	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth >= 768);
		};

		// Check the screen size on initial render and whenever the window is resized
		handleResize();
		
		window.addEventListener('resize', handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	},[]);
	
	const menuItems = [
		{
			id: 'home',
			name: 'Accueil',
			linkName: '#',
		},
		{
			id: 'aboutus',
			name: 'À propos',
			linkName: 'a-propos'
		},
		{
			id: 'products',
			name: 'Nos produits',
			//linkName: 'nos-produits',
			
			subItems: [
				{
					id: 'mobilite',
					displayName: 'Mobilité',
					linkName: 'mobilite'
				},
				{
					id: 'confort-au-quotidien',
					displayName: "Confort au quotidien",
					linkName: 'confort-au-quotidien'
				},
				{
					id: 'incontinence',
					displayName: 'incontinence',
					linkName: 'incontinence'
				},
				{
					id: 'aide-aux-transferts',
					displayName: 'Aide aux transferts',
					linkName: 'aide-aux-transferts'
				},
				{
					id: 'hygiene-soins',
					displayName: 'Hygiène / soins',
					linkName: 'hygiene-soins'
				},
				{
					id: 'instrumentation',
					displayName: 'Instrumentation',
					linkName: 'instrumentation'
				},
			]
		},
		{
			id: 'location-vente',
			name: 'Location / Vente',
			linkName: 'location-vente',
		}
	];
	
	return(
		<header className="header header-transparent rs-nav">
			<Sticky enabled={true} className="sticky-header navbar-expand-lg">
				<div className="menu-bar clearfix">
					<div className="container-fluid clearfix">
						<div className="menu-logo logo-dark">
							<Link to="/"><img src={logoMedikay} alt=""/></Link>
						</div>
						
						<button className={`navbar-toggler collapsed menuicon justify-content-end ${isMenuOpen ? 'open' : ''}`}
							type="button"
							onClick={toggleMenu}
							aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>

						<div className="secondary-menu">
							<ul>
								<li className="num-bx"><a href="tel:0696949621"><i className="fas fa-phone-alt"></i> 06 96 94 96 21</a></li>
								<li className="btn-area"><Link to="/contact" className="btn btn-primary new">Contact<i className="btn-icon-bx fas fa-chevron-right"></i></Link></li>
							</ul>
						</div>
						
						<div className={`menu-links navbar-collapse collapse justify-content-center ${isMenuOpen ? 'show' : ''}`} id="menuDropdown">
							<div className="menu-logo">
								<Link to="/"><img src={logoMedikay} alt=""/></Link>
							</div>
							
							<ul className="nav navbar-nav">
								{menuItems.map((item) => (
									<li
										key={item.id}
											className={`${activeItem === item.id ? 'open' : ''}`}
											onClick={() => toggleSubmenu(item.id)}
										>
										{item.subItems ? (
											<Link to="#">
												{item.name}
												<i className={`fas fa-plus`}></i>
											</Link>
										) : (
											<Link to={`/${item.linkName}`} onClick={handleMenuLinkClick}>
												{item.name}
											</Link>
										)}
										{(isMobileView || activeItem === item.id) && item.subItems && (
											<ul className="sub-menu">
												{item.subItems.map((subItem) => (
													<li key={subItem.id}><Link to={`/${subItem.linkName}`} onClick={handleMenuLinkClick}><span style={{fontSize: '17px'}}>{subItem.displayName}</span></Link></li>
												))}
											</ul>
										)}
									</li>
								))}
								<li className="btn-area d-lg-none">
									<Link to="/contact" onClick={handleMenuLinkClick}>Contact</Link>
								</li>
							</ul>
							
							<ul className="social-media">
							<li><a rel="noreferrer" target="_blank" href="https://wa.me/message/CHVJTHQVYD7JO1/"><i className="fab fa-whatsapp" style={{ fontSize: '21px', marginTop: '9px'}}></i></a></li>
								<li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram" style={{ fontSize: '21px', marginTop: '9px'}}></i></a></li>
								<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-square" style={{ fontSize: '21px', marginTop: '9px'}}></i></a></li>
							</ul>
							
							<div className="menu-close" onClick={handleMenuCloseClick}>
								<i className="ti-close"></i>
							</div>
							
						</div>
					</div>
				</div>
			</Sticky>
		</header>
	);
}

export default Header;