import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Accordion} from 'react-bootstrap';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import verticallift from "../../images/material/verticallift.png";
import walkaid from "../../images/material/walkaid.png";
import hospitalbed from "../../images/material/hospitalbed.png";
import wheelchair from "../../images/material/wheelchair.png";
import cane from "../../images/material/cane.png";
import mattress from "../../images/material/mattress.png";
import aerosol from "../../images/material/aerosol.png";
import breastpump from "../../images/material/breastpump.png";
import bottle from "../../images/material/bottle.png";
import standinglift from "../../images/material/standinglift.png";
//import servicesPic1 from "../../images/services/pic1.jpg";
//import pdf from "../../images/icon/pdf.png";
//import doc from "../../images/icon/doc.png";

import icon1 from "../../images/icon/icon1.png";
import icon3 from "../../images/icon/icon3.png";

class ServiceDetail extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">	
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Location / Vente</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Location / Vente</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-sp1">
						<div className="container">
							<div className="head-text mb-30">
								<h2 className="title mb-15">Notre gamme de matériel médical</h2>
								<p className="mb-0" style={{ fontSize: '21px' }}>Chez<strong style={{ fontWeight: 'bold' }}> Medi'Kay</strong> Martinique, nous comprenons l'importance d'avoir un accès facile à du matériel médical de qualité pour faciliter la récupération et le bien-être.</p>
								<p className="mb-0" style={{ fontSize: '21px' }}>Depuis notre création en 2021, nous nous sommes engagés à fournir une gamme complète de matériel médical de location, avec un service personnalisé pour répondre aux besoins individuels de chacun de nos patients.</p>
								<p className="mb-0" style={{ fontSize: '21px' }}>Nous proposons la location de lit médicalisé, fauteuil roulant, lève-personne, verticalisateur, matelas à air, tire-lait, déambulateur, etc...</p>
							</div>
							<div className="row">
								<div className="col-lg-6">
									<Accordion className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={hospitalbed} alt="Lit médicalisé" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Lit médicalisé</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Le lit médicalisé offre plusieurs avantages, notamment une flexibilité pour <strong style={{ fontWeight: 'bold' }}>s'adapter aux besoins changeants</strong>, une installation aisée, des matelas adaptés pour le confort, une meilleure <strong style={{ fontWeight: 'bold' }}>accessibilité et sécurité</strong>, <strong style={{ fontWeight: 'bold' }}>une réduction des risques de chute</strong>, une meilleure qualité de vie pour les patients, <strong style={{ fontWeight: 'bold' }}>une aide précieuse pour les aidants</strong>, une assistance technique en cas de besoin, une réduction des déchets médicaux, et une responsabilité limitée quant à la gestion de l'équipement. Cette option simplifie la vie des patients et de leurs proches tout en <strong style={{ fontWeight: 'bold' }}>garantissant des soins de qualité à domicile</strong>.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={wheelchair} alt="Fauteuil roulant" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Fauteuil roulant</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Que vous ayez besoin d'un fauteuil roulant manuel pour une utilisation à cout ou terme pour une <strong style={{ fontWeight: 'bold' }}>plus grande autonomie</strong>, Médi’Kay propose une gamme variée de fauteuils roulants à louer. Nos fauteuils sont confortables, faciles à manœuvrer et régulièrement entretenus pour garantir une performance optimale.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={walkaid} alt="Déambulateur" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Déambulateur</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Pour une <strong style={{ fontWeight: 'bold' }}>mobilité accrue à l'intérieur de votre domicile ou lors de déplacements</strong>, nos déambulateurs sont une option idéale. Légers, stables et pliables, ils offrent un soutien supplémentaire tout en étant faciles à transporter. Les déambulateurs de Médi’Kay sont adaptés à différents besoins et préférences.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={bottle} alt="Fauteuil roulant" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Complément nutritionnel oral (CNO)</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Un complément nutritionnel oral (<strong style={{ fontWeight: 'bold' }}>CNO</strong>) est un supplément alimentaire conçu pour être pris par voie orale sous forme de liquide, de poudre, de barre ou de gélule. Il est formulé pour <strong style={{ fontWeight: 'bold' }}>fournir des nutriments supplémentaires, tels que des vitamines, des minéraux, des protéines et d'autres composants nutritionnels importants</strong>, en complément de l'alimentation habituelle. Les CNO sont utilisés pour <strong style={{ fontWeight: 'bold' }}>prévenir ou traiter les carences nutritionnelles</strong>, soutenir la <strong style={{ fontWeight: 'bold' }}>récupération après une maladie ou une chirurgie</strong>, répondre à des besoins nutritionnels spécifiques et améliorer la performance sportive. Ils ne doivent généralement <strong style={{ fontWeight: 'bold' }}>ne pas remplacer une alimentation équilibrée</strong> et doivent être pris sous avis médical, surtout en cas de conditions médicales préexistantes ou de prise de médicaments.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={breastpump} alt="Tire-lait" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Tire-lait</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Un tire-lait est un dispositif médical conçu pour <strong style={{ fontWeight: 'bold' }}>extraire le lait maternel des seins d'une mère qui allaite</strong>. Cet appareil est principalement utilisé pour permettre à la mère de <strong style={{ fontWeight: 'bold' }}>stocker</strong> son lait afin de nourrir son bébé ultérieurement, de <strong style={{ fontWeight: 'bold' }}>soulager</strong> l'engorgement mammaire, de <strong style={{ fontWeight: 'bold' }}>stimuler</strong> la production de lait ou de <strong style={{ fontWeight: 'bold' }}>faciliter</strong> l'allaitement lorsque la mère ne peut pas nourrir son bébé directement au sein. Les tire-laits existent en différentes formes, y compris les modèles manuels et électriques, et ils sont conçus pour être confortables et hygiéniques. Ils offrent aux mères la possibilité de conserver le lait maternel pour nourrir leur bébé à leur convenance, même lorsque la mère n'est pas présente.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="col-lg-6">
									<Accordion className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={standinglift} alt="Lève-personne" style={{ width: '36px', height: '36px', filter: 'brightness(0) invert(0) sepia(1) hue-rotate(180deg) saturate(10000%)' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Lève-personne</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Un lève-personne, est un dispositif médical qui <strong style={{ fontWeight: 'bold' }}>facilite le transfert des personnes ayant des problèmes de mobilité</strong> de la position assise ou couchée à la position debout et vice versa. Il est utilisé dans des environnements de soins de santé pour aider les patients ayant des limitations physiques à participer activement à des activités quotidiennes en toute sécurité. Le lève-personne est composé d'un cadre solide muni de poignées et de sangles pour soutenir le patient, souvent avec un harnais ou une sangle de maintien. Son objectif principal est <strong style={{ fontWeight: 'bold' }}>d'améliorer la mobilité et le bien-être des patients en facilitant leurs déplacements et leurs activités quotidiennes</strong>.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={mattress} alt="Matelas à air" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Matelas à air</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Un matelas à air est un type de dispositif médical utilisé pour le positionnement et la gestion de la pression corporelle, qui utilise de l'air sous pression pour créer une surface de couchage qui réduit la pression corporelle, favorisant ainsi le confort et la <strong style={{ fontWeight: 'bold' }}>prévention des escarres</strong> en réduisant la pression sur les zones à risque, telles que <strong style={{ fontWeight: 'bold' }}>les talons</strong>, <strong style={{ fontWeight: 'bold' }}>les coudes</strong> et <strong style={{ fontWeight: 'bold' }}>le sacrum</strong>, chez les personnes ayant des besoins médicaux spécifiques.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={aerosol} alt="Aérosol médical" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Aérosol médical</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Un aérosol pneumatique médical est un dispositif utilisé en médecine pour <strong style={{ fontWeight: 'bold' }}>administrer des médicaments sous forme d'aérosol</strong>, c'est-à-dire de fines particules liquides ou solides dispersées dans de l'air comprimé ou un gaz sous pression. Ces médicaments sont inhalés par les patients pour le traitement de troubles respiratoires, tels que l'asthme, la bronchite chronique ou d'autres affections pulmonaires. Les dispositifs médicaux d'aérosol pneumatique sont conçus pour une administration précise des médicaments et offrent un moyen efficace de délivrer les traitements directement dans les voies respiratoires du patient pour une <strong style={{ fontWeight: 'bold' }}>absorption rapide et ciblée</strong>.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={verticallift} alt="Vercatilisateur" style={{ width: '36px', height: '36px', filter: 'brightness(0) invert(0) sepia(1) hue-rotate(180deg) saturate(10000%)' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Verticalisateur</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Un verticalisateur est un dispositif médical conçu pour aider les personnes ayant des problèmes de mobilité à <strong style={{ fontWeight: 'bold' }}>passer de la position assise, à la position debout en toute sécurité</strong>. L'objectif principal d'un verticalisateur est de faciliter la mobilité des personnes ayant des limitations physiques, telles que celles causées par des blessures, des maladies, des handicaps ou des affections médicales. Il permet aux patients de se tenir debout, de transférer leur poids et de participer activement à des activités quotidiennes, ce qui peut avoir des avantages significatifs pour leur <strong style={{ fontWeight: 'bold' }}>bien-être physique et psychologique</strong>.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header style={{ display: 'flex', alignItems: 'center' }}>
												<img src={cane} alt="Canne tripode" style={{ width: '36px', height: '36px' }} />
												<span style={{ marginLeft: '20px', fontSize: '21px' }}>Canne tripode</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Les cannes tripodes sont une solution pratique pour ceux qui ont besoin d'un <strong style={{ fontWeight: 'bold' }}>soutien supplémentaire en marchant</strong>. Elles offrent stabilité et équilibre, et sont particulièrement utiles lors de <strong style={{ fontWeight: 'bold' }}>la récupération d'une blessure ou d'une intervention chirurgicale</strong>. Nos cannes tripodes sont ajustables en hauteur pour s'adapter à vos besoins.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</div>
						</div>
					</section>

					<section className="section-area">
						<div className="container">
							{/*}
							<div className="ttr-media mb-30">
								<img src={servicesPic1} className="rounded" alt=""/>
							</div>
							*/}
							<div className="head-text mb-30">
								<h2 className="title mb-15">Comment louer chez Médi’Kay ?</h2>
								<div className="row">
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature1">
											<div className="icon-md">
												<span className="icon-cell">
												<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone-call" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.1" stroke="#1C274C" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
													<path d="M15 7a2 2 0 0 1 2 2"></path>
													<path d="M15 3a6 6 0 0 1 6 6"></path>
												</svg>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title" style={{ fontSize: '21px' }}>Étape 1 : Consultation</h4>
											</div>
										</div>
										<br/>
										<h6 className="text-success" style={{ fontSize: '21px' }}>Contactez notre équipe au 06 96 94 96 21 pour discuter de vos besoins de mobilité et de la durée de location souhaitée.</h6>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature2">
											<div className="icon-md">
												<span className="icon-cell">
												<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-stethoscope" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.1" stroke="#1C274C" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<path d="M6 4h-1a2 2 0 0 0 -2 2v3.5h0a5.5 5.5 0 0 0 11 0v-3.5a2 2 0 0 0 -2 -2h-1"></path>
													<path d="M8 15a6 6 0 1 0 12 0v-3"></path>
													<path d="M11 3v2"></path>
													<path d="M6 3v2"></path>
													<path d="M20 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
												</svg>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title" style={{ fontSize: '21px' }}>Étape 2 : Choix de l'équipement</h4>
											</div>
										</div>
										<br/>
										<h6 className="text-success" style={{ fontSize: '21px' }}>Notre équipe vous guidera pour sélectionner l'équipement de mobilité le mieux adapté à vos besoins.</h6>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature3">
											<div className="icon-md">
												<span className="icon-cell">
												<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-truck-delivery" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.1" stroke="#1C274C" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
													<path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
													<path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"></path>
													<path d="M3 9l4 0"></path>
												</svg>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title" style={{ fontSize: '21px' }}>Étape 3 : Livraison et installation</h4>
											</div>
										</div>
										<br/>
										<h6 className="text-success" style={{ fontSize: '21px' }}>Nous planifierons la livraison à votre domicile et l'installation de l'équipement partout en Martinique.</h6>
									</div>
									<div className="col-lg-6 col-sm-6 mb-30 mb-sm-20">
										<div className="feature-container feature-bx1 feature4">
											<div className="icon-md">
												<span className="icon-cell">
													<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="12" cy="12" r="10" stroke="#1C274C" stroke-width="1.1"/>
														<circle cx="12" cy="12" r="4" stroke="#1C274C" stroke-width="1.1"/>
														<path opacity="0.5" d="M15 9L19 5" stroke="#1C274C" stroke-width="1.1"/>
														<path opacity="0.5" d="M5 19L9 15" stroke="#1C274C" stroke-width="1.1"/>
														<path opacity="0.5" d="M9 9L5 5" stroke="#1C274C" stroke-width="1.1"/>
														<path opacity="0.5" d="M19 19L15 15" stroke="#1C274C" stroke-width="1.1"/>
													</svg>
												</span> 
											</div>
											<div className="icon-content">
												<h4 className="ttr-title" style={{ fontSize: '21px' }}>Étape 4 : Assistance continue</h4>
											</div>
										</div>
										<br/>
										<h6 className="text-success" style={{ fontSize: '21px' }}>Nous sommes disponibles pour répondre à vos questions et vous offrir un soutien continu durant la période de location.</h6>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="section-area">
						<div className="container">
							<div className="head-text mb-30">
								<h2 className="title mb-15">Les avantages de la location de matériel médical</h2>
								<p className="mb-0" style={{ fontSize: '21px' }}>Opter pour la location de matériel médical présente de nombreux avantages pour les patients, les aidants et les prestataires de soins.</p>
								<p className="mb-0" style={{ fontSize: '21px' }}>Voici quelques-uns de ces principaux avantages :</p>
							</div>
							<div className="row">
								<div className="col-lg-6 mb-50">
									<Accordion className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Coût réduit</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>La location de matériel médical est souvent plus économique que l'achat, ce qui permet aux patients de faire face aux dépenses de santé sans supporter le coût total de l'équipement.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Flexibilité</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>La location permet aux patients de s'adapter à leurs besoins en constante évolution. Ils peuvent louer l'équipement aussi longtemps qu'il est nécessaire, puis le rendre une fois qu'ils n'en ont plus besoin.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Variété de choix</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Les prestataires de location proposent généralement une gamme variée d'équipements médicaux, ce qui permet aux patients de choisir celui qui correspond le mieux à leurs besoins spécifiques.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Mise à niveau facile</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Si les besoins médicaux du patient changent, il est plus facile de passer à un équipement différent en le louant plutôt qu'en l'achetant.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Accessibilité immédiate</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>La location permet d'obtenir rapidement l'équipement nécessaire sans attendre les procédures d'achat et de livraison.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="col-lg-6 mb-50">
									<Accordion className="accordion ttr-accordion1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Réduction des déchets</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>La location réduit la création de déchets médicaux, car l'équipement est retourné, nettoyé et réutilisé pour d'autres patients.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Pas de stockage nécessaire</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Les patients n'ont pas besoin de s'inquiéter de l'espace de stockage à domicile, car ils peuvent restituer l'équipement une fois qu'ils n'en ont plus besoin.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Aide à la décision</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>La location permet aux patients de tester l'équipement avant de s'engager dans un achat coûteux, ce qui les aide à prendre des décisions éclairées.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Assistance technique</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>Les prestataires de location offrent un soutien technique pour aider les patients à utiliser correctement l'équipement.</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>
												<span style={{ fontSize: '21px' }}>Responsabilité limitée</span>
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0" style={{ fontSize: '18px' }}>En louant, les patients ne sont pas responsables de la revente ou de la disposition de l'équipement une fois qu'ils n'en ont plus besoin.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="head-text mb-30">
									<p className="mb-0" style={{ fontSize: '21px' }}>En résumé, la location de matériel médical offre une <strong style={{ fontWeight: 'bold' }}>solution pratique</strong>, <strong style={{ fontWeight: 'bold' }}>économique et flexible</strong> pour répondre aux besoins de santé à court ou à long terme. Elle permet aux patients de bénéficier de l'équipement nécessaire sans les tracas liés à l'achat et à la gestion à long terme de l'équipement médical.</p>
									<br/>
									<p className="mb-0" style={{ fontSize: '21px' }}>Chez <strong style={{ fontWeight: 'bold' }}>Médi’Kay</strong>, nous nous engageons à <strong style={{ fontWeight: 'bold' }}>améliorer votre mobilité et qualité de vie</strong>. <Link to="/contact">Contactez-nous</Link> dès aujourd'hui pour discuter de vos besoins de location de matériel de mobilité.</p>
							</div>
							</div>
						</div>
					</section>

					<section className="section-area section-sp1">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx4 feature4 text-center">
										<div className="icon-md feature-icon">
											<img src={icon1} alt=""/>
										</div>
										<div className="icon-content">
											<h5 className="ttr-title">Téléphone</h5>
											<p style={{fontSize: '20px'}}>06 96 94 96 21</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx4 feature3 text-center">
										<div className="icon-md feature-icon">
											<img src={icon3} alt=""/>
										</div>
										<div className="icon-content">
											<h5 className="ttr-title">Adresse email</h5>
											<p style={{fontSize: '20px'}}>contact@medikay.fr</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

				</div>
				
			</>
		);
	}
}

export default ServiceDetail;