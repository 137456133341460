import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import sofa from "../../images/material/sofa.png";
import pushchair from "../../images/material/pushchair.png";
import shower from "../../images/material/shower.png";
import commode from "../../images/material/commode.png";
import bed from "../../images/material/bed.png";
import bag from "../../images/material/bag.png";

class Confort extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">	
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Confort au quotidien</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Confort au quotidien</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<img src={bed} alt="lit médicalisé" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Lits médicalisés</h4>
											<p style={{ fontSize: '18px' }}>Confortable et ajustable, le lit médicalisé offre un soutien essentiel pour les soins médicaux.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={pushchair} alt="Fauteuil à pousser / coquille" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Fauteuils à pousser / coquille</h4>
											<p style={{ fontSize: '18px' }}>Facile à manœuvrer, ce fauteuil offre une mobilité accrue, tout en garantissant confort et sécurité.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={sofa} alt="fauteuil releveur confort" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Fauteuils releveurs confort</h4>
											<p style={{ fontSize: '18px' }}>Confortable et sécurisé, le fauteuil releveur offre un soutien optimal pour le levage.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={commode} alt="chaise percée" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Chaises percées</h4>
											<p style={{ fontSize: '18px' }}>Une solution pratique, hygiénique et confortable pour les besoins d'aisance en toute simplicité.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={shower} alt="chaise de douche" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Chaises de douche</h4>
											<p style={{ fontSize: '18px' }}>Une chaise de douche sécurisée et confortable pour votre tranquillité d'esprit.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={bag} alt="accessoires" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Accessoires</h4>
											<p style={{ fontSize: '18px' }}>Un ensemble complet et pratique avec tous les accessoires essentiels contenus dans votre malette.</p>
										</div>
									</div>
								</div>
							</div>	
						</div>
					</section>
				</div>
				
			</>
		);
	}
}

export default Confort;