import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

class Legales extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Mentions légales</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Mentions légales</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="">
									<div className="clearfix">
										<div className="head-text mb-30">
											<h2 className="title mb-15">Siège social</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>Adresse :</span> Médi'Kay - 12 rue des arts et métiers, 97200 Fort-de-France</p>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>Téléphone :</span> 06 96 94 96 21</p>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>Adresse email :</span> contact@medikay.fr</p>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>SIRET :</span> 90375310100018</p>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>SIREN :</span> 903753101</p>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>RCS :</span> Fort-de-France B 903 753 101</p>
										</div>
										<div className="head-text mb-30">
											<h2 className="title mb-15">Directeur de la publication</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Le directeur de la publication du site est Monsieur Boris RAMIER, en qualité de Gérant.</p>
										</div>
										<div className="head-text mb-30">
											<h2 className="title mb-15">Conception et réalisation</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Le site web www.medikay.fr est supervisé par la société VIRTUAVA, SAS au capital de 1 000 €, inscrite au RCS de Fort-de-France sous le numéro SIREN 922 540 034 et dont le SIRET est 922 540 034 00019, Code APE 6311Z.</p>
										</div>
										<div className="head-text mb-30">
											<h2 className="title mb-15">Hébergement</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Le site web www.medikay.fr est hébergé par Vercel, www.vercel.com, dont le siège social est situé à San Francisco, aux États-Unis.</p>
										</div>
										<div className="head-text mb-30">
											<h2 className="title mb-15">Crédits photos</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Freepik.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default Legales;