import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import walkingtripode from "../../images/material/walkingtripode.png";
import walker from "../../images/material/walker.png";
import transfert from "../../images/material/transfert.png";
import roulant from "../../images/material/roulant.png";
import comfort from "../../images/material/comfort.png";
import pillow from "../../images/material/pillow.png";
import rollator from "../../images/material/rollator.png";

class Mobility extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">	
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Mobilité</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Mobilité</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<img src={roulant} alt="fauteuil roulant" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Fauteuils roulants</h4>
											<p style={{ fontSize: '18px' }}>Un moyen de déplacement essentiel, ce fauteuil offre mobilité et confort pour une vie active.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={transfert} alt="fauteuil de transfert" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Fauteuils de transfert</h4>
											<p style={{ fontSize: '18px' }}>Conçu pour faciliter les transferts en toute simplicité, tout en offrant un confort optimal.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={comfort} alt="fauteuil roulant de confort" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Fauteuils roulants de confort</h4>
											<p style={{ fontSize: '18px' }}>Offrant un confort supérieur, ce fauteuil roulant assure une mobilité aisée avec un soutien ergonomique.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={pillow} alt="coussins statiques" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Coussins anti-escarres</h4>
											<p style={{ fontSize: '18px' }}>Des coussins conçus pour le confort, offrant un soutien stable et une position assise agréable.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={walkingtripode} alt="cannes" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Cannes</h4>
											<p style={{ fontSize: '18px' }}>Un compagnon de marche léger et pratique pour plus de stabilité et de mobilité.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature1">
										<div className="feature-box-xl mb-20">
											<img src={walker} alt="déambulateur" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Déambulateurs</h4>
											<p style={{ fontSize: '18px' }}>Un support sûr pour la marche, permettant de se déplacer en toute confiance.</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx2 feature2">
										<div className="feature-box-xl mb-20">
											<img src={rollator} alt="rollator" style={{ width: '80px', height: '80px' }} />
										</div>
										<div className="icon-content">
											<h4 className="ttr-title">Rollators</h4>
											<p style={{ fontSize: '18px' }}>Un déambulateur avec roues pour une mobilité accrue, offrant soutien et sécurité lors des déplacements.</p>
										</div>
									</div>
								</div>
							</div>	
						</div>
					</section>
				</div>
				
			</>
		);
	}
}

export default Mobility;