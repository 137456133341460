import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import pic from "../../images/testimonials/pic.png";
import blogGridPic1 from "../../images/blog/grid/pic1.jpg";

// Blog Content
const blogPost = [
	{ 
		thumb: blogGridPic1,
		authorPic: pic,
		title: "Nous sommes enfin là !",		
		author: "Boris Ramier",
		date: "16 Septembre 2023",
	},
]

class BlogGrid extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Blog</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Blog</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								{blogPost.map((blogPost, index) =>(
									<div key={index} className="col-xl-4 col-md-6">
										<div className="blog-card mb-30">
											<div className="post-media">
												<Link to="/blog-details"><img src={blogPost.thumb} alt=""/></Link>
											</div>
											<div className="post-info">
												<ul className="post-meta">
													<li className="author"><Link to="/blog-details"><img src={blogPost.authorPic} alt=""/> {blogPost.author}</Link></li>
													<li className="date"><i className="far fa-calendar-alt"></i> {blogPost.date}</li>
												</ul>
												<h4 className="post-title"><Link to="/blog-details">{blogPost.title}</Link></h4>		
												<Link to="/blog-details" className="btn btn-outline-primary btn-sm">En savoir plus <i className="btn-icon-bx fas fa-chevron-right"></i></Link>		
											</div>
										</div>							
									</div>
								))}
							</div>
							{/*
							<div className="row">
								<div className="col-lg-12">
									<div className="pagination-bx text-center mb-30 clearfix">
										<ul className="pagination">
											<li className="previous"><Link to="#">Prev</Link></li>
											<li className="active"><Link to="#">1</Link></li>
											<li><Link to="#">2</Link></li>
											<li><Link to="#">3</Link></li>
											<li className="next"><Link to="#">Next</Link></li>
										</ul>
									</div>
								</div>
							</div>
							*/}
						</div>
					</section>
					
				</div>
			
			</>
		);
	}
}

export default BlogGrid;