import React from 'react';

// Elements
import MainBannerSection from "../elements/main-banner";
import AboutSection from "../elements/about";
import WorkSection from "../elements/work";
//import AppointmentSection from "../elements/appointment";
import icon1 from "../../images/icon/icon1.png";
//import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";

function Index(){
	
	return(
		<>
			
			<MainBannerSection />
			
			<AboutSection />
			
			<WorkSection />
			
			{/*<AppointmentSection />*/}

			<section className="section-area section-sp1" style={{ marginTop: '-80px' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-4 col-md-6 mb-30">
							<div className="feature-container feature-bx4 feature4 text-center">
								<div className="icon-md feature-icon">
									<img src={icon1} alt=""/>
								</div>
								<div className="icon-content">
									<h5 className="ttr-title">Téléphone</h5>
									<p style={{fontSize: '20px'}}>06 96 94 96 21</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 mb-30">
							<div className="feature-container feature-bx4 feature3 text-center">
								<div className="icon-md feature-icon">
									<img src={icon3} alt=""/>
								</div>
								<div className="icon-content">
									<h5 className="ttr-title">Adresse email</h5>
									<p style={{fontSize: '20px'}}>contact@medikay.fr</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>								
		</>
		
	);
}

export default Index;