import React, { Component } from 'react';

// Import Images
import bg1 from '../../images/background/line-bg1.png';
import ptImg1 from '../../images/shap/circle-orange.png';
import ptImg2 from '../../images/shap/plus-orange.png';
import ptImg3 from '../../images/shap/circle-dots.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp5 work-area" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:" no-repeat", backgroundPosition: "center", backgroundSize: "100%"}}>
					<div className="container-sm">
						<div className="heading-bx text-center">
							<h4 className="title-ext text-secondary">Comment procédons-nous ?</h4>
							<h2 className="title">Notre approche</h2>
						</div>
						<div className="row">
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">01</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Prise de contact</h5>
										<p style={{ fontSize: '18px' }}>Avec ou sans ordonnance, votre premier réflexe est d'appeler <strong style={{ fontWeight: 'bold' }}>Médi'Kay</strong> au <strong style={{ fontWeight: 'bold' }}>06 96 94 96 21</strong> pour vous orienter au mieux.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">02</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Prise en charge</h5>
										<p style={{ fontSize: '18px' }}>Une fois le point fait avec vous, nous préparerons directement votre prescription ou votre commande.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">03</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Livraison</h5>
										<p style={{ fontSize: '18px' }}>Votre prescription ou votre commande est prête. <strong style={{ fontWeight: 'bold' }}>Médi'Kay</strong> vous en informe et vous livre à domicile sur toute la Martinique.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-num-bx">04</div>
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Suivi et Conseil</h5>
										<p style={{ fontSize: '18px' }}>Nous vous accompagnerons tout au long de votre parcours de soins et veillerons à ce que vous ne manquiez de rien.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg1} alt=""/>
					<img className="pt-img2 animate2" src={ptImg2} alt=""/>
					<img className="pt-img3 animate3" src={ptImg3} alt=""/>
				</section>
			</>
		);
	}
}

export default aboutSection;