import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Import Images
import bnrImg1 from '../../images/banner/img1.jpg';
import pic1 from '../../images/about/pic-1.jpg';
import icon1 from '../../images/icon/icon1.png';
//import icon2 from "../../images/icon/icon2.png";
import icon3 from '../../images/icon/icon3.png';
import animateWave from '../../images/shap/wave-blue.png';
import animate2 from '../../images/shap/circle-dots.png';
import animateRotate from '../../images/shap/plus-blue.png';
import baseUrl from '../../utils/baseUrl';

const MySwal = withReactContent(Swal);

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      choice: '',
      message: '',
    };
  }
  render() {
    const alertContent = (content) => {
      const { title, text, icon } = content;
      MySwal.fire({
        title,
        text,
        icon,
        timer: 7000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        const url = `${baseUrl}/sendMail`;
        const data = {
          from: 'contact-relay@medikay.fr',
          to: 'contact@medikay.fr',
          subject: `Médi'Kay formulaire de contact - site web`,

          content: `
					<p>Vous avez été contactés par : ${event.target.name.value}</p>
					<p>Numéro de téléphone : ${event.target.phone.value.replace(/\D/g, '')}</p>
					<p>Adresse email : ${event.target.email.value}</p>
					<p>Objet : ${event.target.choice.value}</p>
					<p>Message : ${event.target.message.value}</p>
					`,
        };
        const response = await axios.post(url, { data });
        const contentData = {
          title: 'Félicitations!',
          text: 'Nous avons bien reçu votre message. Nous vous répondrons dans un délai maximal de 48h. Merci de votre compréhension.',
          icon: 'success',
        };
        alertContent(contentData);
        this.setState({
          name: '',
          email: '',
          phone: '',
          choice: '',
          message: '',
        });
      } catch (error) {
        const contentData = {
          title: 'Erreur',
          text: "Il semble y avoir un incident lors de l'envoi de votre message. Merci de bien vouloir réessayer.",
          icon: 'error',
        };
        alertContent(contentData);
      }
    };
    return (
      <>
        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner banner-lg contact-banner"
              style={{ backgroundImage: 'url(' + bnrImg1 + ')' }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Contact</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{' '}
                          Accueil
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Contact
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={animateWave} alt="" />
              <img className="pt-img2 animate2" src={animate2} alt="" />
              <img
                className="pt-img3 animate-rotate"
                src={animateRotate}
                alt=""
              />
            </div>
          </div>

          <section className="">
            <div className="container">
              <div className="contact-wraper">
                <div className="row">
                  <div className="col-lg-6 mb-30">
                    <form
                      className="form-wraper contact-form ajax-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="ajax-message"></div>
                      <div className="row">
                        <div className="form-group col-md-12">
                          <input
                            name="name"
                            type="text"
                            required
                            className="form-control"
                            style={{ fontSize: '21px' }}
                            placeholder="Nom Prénom"
                            value={this.state.name}
                            onChange={(event) => {
                              this.setState({ name: event.target.value });
                            }}
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <div style={{ position: 'relative' }}>
                            <select
                              required
                              name="choice"
                              className="form-select form-control"
                              style={{ fontSize: '21px' }}
                              onChange={(event) => {
                                this.setState({ choice: event.target.value });
                              }}
                            >
                              <option value="" disabled selected>
                                Sélectionnez l'objet de votre demande
                              </option>
                              <option value="Demande d'information">
                                Demande d'information
                              </option>
                              <option value="Ordonnance">Ordonnance</option>
                              <option value="Conseil">Conseil</option>
                              <option value="Autre">Autre</option>
                            </select>
                            <i
                              className="fas fa-chevron-down"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className="form-group col-md-12">
                          <input
                            name="phone"
                            type="text"
                            required
                            className="form-control"
                            pattern="[0-9]{10}"
                            style={{ fontSize: '21px' }}
                            placeholder="Téléphone"
                            value={this.state.phone}
                            onChange={(event) => {
                              let phoneNumber = event.target.value.replace(
                                /\D/g,
                                ''
                              ); // Supprime les caractères non numériques
                              if (phoneNumber.length > 10) {
                                phoneNumber = phoneNumber.slice(0, 10); // Limite la longueur à 10 caractères
                              }
                              this.setState({ phone: phoneNumber });
                            }}
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            style={{ fontSize: '21px' }}
                            placeholder="Adresse email (recommandée)"
                            value={this.state.email}
                            onChange={(event) => {
                              this.setState({ email: event.target.value });
                            }}
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <textarea
                            name="message"
                            required
                            className="form-control"
                            style={{ fontSize: '21px' }}
                            placeholder="Tapez votre message..."
                            value={this.state.message}
                            onChange={(event) => {
                              this.setState({ message: event.target.value });
                            }}
                          />
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            formMethod="POST"
                            className="btn w-100 btn-secondary btn-lg"
                            style={{ fontSize: '21px' }}
                          >
                            Envoyer
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6 mb-30">
                    <div
                      className="contact-info ovpr-dark"
                      style={{ backgroundImage: 'url(' + pic1 + ')' }}
                    >
                      <div className="info-inner">
                        <h4 className="title mb-30">
                          Contactez-nous pour plus d'informations
                        </h4>
                        {/*
												<div className="icon-box">
													<h6 className="title"><i className="ti-map-alt"></i>Localisation</h6>		
													<p>12 Rue Des Arts Et Métiers, 97200 Fort-de-France</p>
												</div>
												*/}
                        <div className="icon-box">
                          <h5 className="title">
                            <i className="ti-id-badge"></i>Email &amp; Téléphone
                          </h5>
                          <h6 className="text-light">contact@medikay.fr</h6>
                          <h6 className="text-light">06 96 94 96 21</h6>
                        </div>
                        <div className="icon-box">
                          <h5 className="title">
                            <i className="ti-world"></i>Suivez-nous
                          </h5>

                          <ul className="social-media">
                            <li>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://wa.me/message/CHVJTHQVYD7JO1/"
                              >
                                <i
                                  className="fab fa-whatsapp"
                                  style={{
                                    fontSize: '21px',
                                    color: 'green',
                                    marginTop: '9px',
                                  }}
                                ></i>
                              </a>
                            </li>
                            {/* <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/"><i className="fab fa-instagram" style={{ fontSize: '21px', color: 'fuchsia', marginTop: '9px'}}></i></a></li>
														<li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><i className="fab fa-facebook-square" style={{ fontSize: '21px', marginTop: '9px'}}></i></a></li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature4 text-center">
                    <div className="icon-md feature-icon">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Téléphone</h5>
                      <p style={{ fontSize: '20px' }}>06 96 94 96 21</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-30">
                  <div className="feature-container feature-bx4 feature3 text-center">
                    <div className="icon-md feature-icon">
                      <img src={icon3} alt="" />
                    </div>
                    <div className="icon-content">
                      <h5 className="ttr-title">Adresse email</h5>
                      <p style={{ fontSize: '20px' }}>contact@medikay.fr</p>
                    </div>
                  </div>
                </div>
                {/*
												<div className="icon-box">
													<h6 className="title"><i className="ti-map-alt"></i>Localisation</h6>		
													<p>12 Rue Des Arts Et Métiers, 97200 Fort-de-France</p>
												</div>
												
								<div className="col-lg-4 col-md-6 mb-30">
									<div className="feature-container feature-bx4 feature2">
										<div className="icon-md feature-icon">
											<img src={icon2} alt=""/>
										</div>
										<div className="icon-content">
											<h5 className="ttr-title">Localisation</h5>
											<p>12 Rue Des Arts Et Métiers, 97200 FDF</p>
										</div>
									</div>
								</div>
								*/}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default ContactUs;
