import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

class Privacy extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h1>Confidentialité</h1>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Accueil</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Confidentialité</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row">
								<div className="">
									<div className="clearfix">
									<div className="head-text mb-30">
										<h2 className="title mb-15">Politique de Confidentialité des Données de Médi'Kay</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>
												Chez Médi'Kay, nous accordons une importance primordiale à la protection de vos données personnelles. Cette politique de confidentialité a été élaborée pour vous informer sur la manière dont nous collectons, utilisons, partageons et protégeons vos données lorsque vous utilisez notre site Web et nos services. Veuillez prendre le temps de lire attentivement cette politique pour comprendre comment nous traitons vos informations personnelles.
											</p>
										</div>

										<div>
											<h2>Collecte de Données Personnelles</h2>
											<div>
												<p className="mb-0" style={{ fontSize: '21px' }}>Nous collectons les données personnelles suivantes :</p>
												<ul>
													<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px' }}>• Informations de contact telles que votre nom, adresse e-mail, numéro de téléphone et adresse postale.</li>
													<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Informations professionnelles, le cas échéant.</li>
													<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Informations sur vos interactions avec notre site, y compris les pages que vous consultez et les actions que vous entreprenez.</li>
												</ul>
											</div>
										</div>

										<div>
											<h2>Utilisation des Cookies</h2>
											<div>
												<p className="mb-0" style={{ fontSize: '21px' }}>Nous utilisons des cookies et des technologies similaires pour collecter des informations sur votre utilisation de notre site Web. Les cookies sont de petits fichiers texte stockés sur votre appareil qui nous aident à améliorer votre expérience de navigation.</p>
												<ul></ul>
											</div>
										</div>

										<div>
											<h2>Utilisation de vos Données Personnelles</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Nous utilisons vos données personnelles pour les finalités suivantes :</p>
											<ul>
												<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Vous fournir les informations, les produits et les services que vous demandez.</li>
												<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Gérer et améliorer notre site Web.</li>
												<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Répondre à vos questions et demandes soumises via notre formulaire de contact.</li>
												<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Vous envoyer des mises à jour, des newsletters et d'autres communications liées à Médi'Kay, à condition que vous ayez consenti à les recevoir.</li>
											</ul>
										</div>

										<div>
											<h2>Partage de vos Données Personnelles</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Nous pouvons partager vos données personnelles avec des tiers dans les cas suivants :</p>
											<ul>
												<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Lorsque cela est nécessaire pour fournir les produits et services que vous avez demandés.</li>
												<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Avec nos partenaires commerciaux et fournisseurs de services, dans la mesure nécessaire pour exécuter des fonctions en notre nom.</li>
												<li className="mb-0" style={{ fontSize: '21px', paddingLeft: '20px'  }}>• Pour nous conformer aux lois applicables, aux réglementations gouvernementales, aux ordonnances judiciaires ou pour protéger nos droits légaux.</li>
											</ul>
										</div>

										<div>
											<h2>Sécurité de vos Données Personnelles</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles contre les accès non autorisés, la divulgation, la modification ou la destruction. Cela inclut la sécurisation de notre site Web et de nos bases de données.</p>
											<ul></ul>
										</div>

										<div>
											<h2>Vos Choix et Vos Droits</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Vous avez le droit de demander l'accès à vos données personnelles, de les corriger, de les supprimer ou de vous opposer à leur traitement. Vous pouvez également retirer votre consentement à tout moment pour les communications marketing. Pour exercer ces droits, veuillez nous contacter à l'adresse indiquée ci-dessous.</p>
											<ul></ul>
										</div>

										<div>
											<h2>Modifications de cette Politique de Confidentialité</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page, et la date de la dernière mise à jour sera indiquée en haut de la page.</p>
											<ul></ul>
										</div>

										<div>
											<h2>Contactez-nous</h2>
											<p className="mb-0" style={{ fontSize: '21px' }}>Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité ou la manière dont nous traitons vos données personnelles, veuillez nous contacter à l'adresse suivante :</p>
											<br/>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>Adresse :</span> Médi'Kay - 12 rue des arts et métiers, 97200 Fort-de-France</p>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>Téléphone :</span> 06 96 94 96 21</p>
											<p className="mb-0" style={{ fontSize: '21px' }}><span style={{ fontWeight: 'bold' }}>Adresse email :</span> contact@medikay.fr</p>
											<br/>
											<p className="mb-0" style={{ fontSize: '21px' }}>Nous nous engageons à répondre rapidement à toutes les demandes de renseignements concernant vos données personnelles.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					
				</div>
				
			</>
		);
	}
}

export default Privacy;